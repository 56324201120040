<template>
  <div>
    <q-form ref="editForm" class="q-mb-sm">
      <c-card class="cardClassDetailForm" title="LBLDETAIL">
        <template slot="card-button">
          <q-btn-group outline >
            <c-appr-btn 
              ref="appr-btn"
              name="internal-year-plan-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="approvalYear"
              @callbackApprAction="approvalYearCallback"
              @requestAfterAction="getDetail('save')"
            />
            <!--저장-->
            <c-btn 
              v-if="editable && !disabled" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="year"
              :mappingType="mappingType"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveYear"
              @btnCallback="saveYearCallback" />
            <!--삭제-->
            <c-btn v-if="editable&&!disabled&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteYear" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <!--실시계획서명-->
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="LBL0010105"
              name="planName"
              v-model="year.planName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
            <!-- 년도 -->
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              default="today"
              type="year"
              label="LBLYEAR"
              name="planYear"
              v-model="year.planYear"
            />
          </div>
          <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
            <!-- 주관부서 -->
            <c-dept
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="LBL0010106"
              name="deptCd"
              v-model="year.deptCd">
            </c-dept>
          </div>
          <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
            <c-plant
              :required="true"
              :disabled="disabled"
              :editable="editable"
              name="plantCd"
              v-model="year.plantCd">
            </c-plant>
          </div>
          <div class="col-12">
            <!--심사범위-->
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="8"
              label="LBL0010108"
              name="auditScope"
              v-model="year.auditScope">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
        <!--실시계획 목록-->
        <c-table
          ref="plantable"
          title="LBL0010109"
          :columns="gridColumns"
          :data="grid.data"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="deptCd"
          checkDisableColumn="deptFlag"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!--제외-->
              <c-btn 
                v-if="editable&&!disabled" 
                label="LBLEXCEPT" icon="remove" 
                @btnClicked="deleteTarget" />
              <!--추가-->
              <c-btn 
                v-if="editable&&!disabled" 
                label="LBLADD" icon="add" 
                @btnClicked="addTarget" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <q-checkbox
              v-if="props.rowIndex !== (grid.data.length - 1)"
              v-model="props.row[col.name]"
              :disable="!editable || disabled"
              color="orange-custom" 
              true-value="Y"
              false-value="N"
              dense
              @input="datachange(props, col, 'c')"
            />
            <c-textarea-column
              v-else
              :editable="editable&&!disabled"
              :col="col"
              :props="props"
              class="text-left"
              @datachange="datachange(props, col, 't')"
            />
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <!--심사팀 목록-->
        <c-table
          ref="teamtable"
          title="LBL0010110"
          :columns="gridTeam.columns"
          :data="year.auditTeams"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="userId"
        >
          <!-- @linkClick="linkClick" -->
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!--제외-->
              <c-btn 
                v-if="editable&&!disabled" 
                label="LBLEXCEPT" icon="remove" 
                @btnClicked="deleteTeam" />
              <!--추가-->
              <c-btn 
                v-if="editable&&!disabled" 
                label="LBLADD" icon="add" 
                @btnClicked="addTeam" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'internal-year-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalYearPlanId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      year: {
        saiInternalYearPlanId: '',  // 연간 내부감사 일련번호
        plantCd: '',  // 사업장코드
        planYear: '',  // 심사년
        planName: '',  // 실시계획서명
        auditScope: '',  // 심사범위
        deptCd: '',  // 주관부서코드
        saiInternalYearPlanStepCd: '',  // 연간 내부심사 진행상태
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        auditMonth: [], // 심사월
        targetDepts: [], // 피심사팀
        deleteTargetDepts: [], // 피심사팀
        auditTeams: [], // 심사팀
        deleteAuditTeams: [], // 심사팀 [삭제]
      },
      grid: {
        defaultColumns: [
          {
            name: 'deptName',
            field: 'deptName',
            //피심사팀
            label: 'LBL0010111',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      gridTeam: {
        columns: [],
      },
      editable: true,
      mappingType: 'PUT',
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.year.saiInternalYearPlanStepCd === 'IYP0000005'
        || this.year.approvalStatusCd === 'ASC0000001'
    },
    isOld() {
      return Boolean(this.popupParam.saiInternalYearPlanId);
    },
    apprEditable() {
      return this.isOld
        && this.year.saiInternalYearPlanStepCd === 'IYP0000001'
        // && this.year.approvalStatusCd !== 'ASC9999999'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.year.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.year.approvalStatusCd, 
        apprEditable: this.apprEditable,
        param: this.year, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000010', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiInternalYearPlanId: this.year.saiInternalYearPlanId,
          isApprContent: true
        },
        approvalRequestName: this.year.planName, // 결재요청명 (문서 title)
        approvalConnId: this.year.saiInternalYearPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    gridHeight() {
      let height = this.contentHeight - 400;
      if (height < 400) {
        height = 400;
      }
      return String(400) + 'px';
    },
    gridColumns() {
      let columns = this.$_.cloneDeep(this.grid.defaultColumns);
      for(let i = 1; i <= 12; i++) {
        columns.push({
          name: `mon${i}`,
          field: `mon${i}`,
          yearmonth: `${this.year.planYear}-${this.$_.padStart(i, 2, '0')}`,
          label: `${i}`+ this.$comm.getLangLabel('LBL0010043'), //월 
          align: 'center',
          style: 'width:70px',
          sortable: true,
          type: 'custom',
        })
      }
      return columns;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.internal.yearPlan.get.url;
      this.saveUrl = transactionConfig.sai.internal.yearPlan.insert.url;
      this.deleteUrl = transactionConfig.sai.internal.yearPlan.delete.url;
      this.approvalUrl = transactionConfig.sai.internal.yearPlan.update.url;
      // code setting
      this.$comm.getComboItems('SAI_INTERNAL_AUDIT_TEAM_ROLE_CD').then(_result => {
        this.gridTeam.columns = [
          {
            name: 'saiInternalAuditTeamRoleCd',
            field: 'saiInternalAuditTeamRoleCd',
            //역할
            label: 'LBLROLE',
            align: 'center',
            style: 'width:30%',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            //이름
            label: 'LBLNAME',
            align: 'center',
            style: 'width:30%',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            //심사팀
            label: 'LBL0010112',
            align: 'center',
            style: 'width:40%',
            sortable: true,
          },
        ]
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiInternalYearPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiInternalYearPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.year, _result.data);

          if (!this.year.targetDepts) this.year.targetDepts = [];
          let _auditmon = {
            deptFlag: true,
            deptName: this.$label('LBLREMARK'),
            mon1: '',
            mon2: '',
            mon3: '',
            mon4: '',
            mon5: '',
            mon6: '',
            mon7: '',
            mon8: '',
            mon9: '',
            mon10: '',
            mon11: '',
            mon12: '',
          }
          this.$_.forEach(this.year.auditMonth, month => {
            let _mon = this.$_.replace(month.yearmonth, `${this.year.planYear}-`, '')
            _mon = !isNaN(_mon) ? Number(_mon) : 0;
            if (_mon) {
              this.$set(_auditmon, `mon${_mon}`, month.remark)
            }
          })
          this.grid.data = [];
          this.grid.data = this.$_.concat(this.year.targetDepts, [_auditmon])

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 신규등록인 경우
        this.$set(this.year, 'saiInternalYearPlanStepCd', 'IYP0000001'); // 직성중
        this.year.auditMonth = [];
        this.$_.forEach(this.gridColumns, col => {
          if (col.name === 'deptName') return;
          this.year.auditMonth.push({
            yearmonth: col.yearmonth,
            remark: '',
            editFlag: 'C',
            regUserId: this.$store.getters.user.userId,
          })
        });
        this.grid.data = [{
          deptFlag: true,
          deptName: this.$label('LBLREMARK'),
          mon1: '',
          mon2: '',
          mon3: '',
          mon4: '',
          mon5: '',
          mon6: '',
          mon7: '',
          mon8: '',
          mon9: '',
          mon10: '',
          mon11: '',
          mon12: '',
        }];
      }
    },
    datachange(props, col, type) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }

      if (type === 't') {
        // textarea
        if (this.year.auditMonth && this.year.auditMonth.length > 0) {
          let _f_data = this.$_.find(this.year.auditMonth, month => {
            return month.yearmonth === col.yearmonth
          })
          if (_f_data) {
            this.$set(_f_data, 'remark', props.row[col.name])
            if (_f_data['editFlag'] !== 'C') {
              _f_data['editFlag'] = 'U'
              _f_data['chgUserId'] = this.$store.getters.user.userId
            }
          }
        }

      }
    },
    deleteYear() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiInternalYearPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.year.regUserId = this.$store.getters.user.userId
        this.year.chgUserId = this.$store.getters.user.userId

        // 피심사팀
        if (this.grid.data && this.grid.data.length > 1) {
          let _data = this.grid.data.slice(0, this.grid.data.length - 1)
          this.year.targetDepts = this.$_.cloneDeep(_data);
        }

        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveYear() {
      if (this.popupParam.saiInternalYearPlanId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.internal.yearPlan.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.internal.yearPlan.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  //확인
            message: 'MSGSAVE', // 저장하시겠습니까?   //저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.saveValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveYearCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.saiInternalYearPlanId = _result.data
      this.getDetail();
    },
    approvalYear() {
      /**
       * 실시계획 목록
       *  - 피심사팀이 하나 이상인지?
       *  - 일정이 없는 피심사팀이 있는지?
       * 
       * 심사팀 목록
       *  - 심사팀원이 하나 이상있는지?
       */
      if (!this.grid.data || this.grid.data.length < 2) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '실시계획에 피심사팀을 하나 이상 지정하시기 바랍니다.', // 실시계획에 피심사팀을 하나 이상 지정하시기 바랍니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      let _checkData = this.grid.data.slice(0, this.grid.data.length - 2)
      let _ing = false;
      this.$_.forEach(_checkData, item => {
        this.$_.forEach(this.gridColumns, col => {
          if (col.name === 'deptName') return
          if (item[col.name] === 'Y') {
            _ing = true;
            return false;
          }
        })

        if (_ing) return false;
      })
      if (!_ing) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '일정이 없는 피심사팀이 있습니다.\n\r일정을 체크하시기 바랍니다.', // 일정이 없는 피심사팀이 있습니다.\n\r일정을 체크하시기 바랍니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      if (!this.year.auditTeams || this.year.auditTeams.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '심사팀을 구성하시기 바랍니다.', // 심사팀을 구성하시기 바랍니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  //확인
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.approvalValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalYearCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    addTarget() {
      this.popupOptions.title = 'LBL0000518'; // 부서조회
      this.popupOptions.param = {
        plantCd: this.year.plantCd,
      }
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { deptCd: item.deptCd }) === -1) {
            this.grid.data.splice(0, 0, {
              saiInternalYearPlanId: this.popupParam.saiInternalYearPlanId,
              deptCd: item.deptCd,
              deptName: item.deptName,
              mon1: 'N',
              mon2: 'N',
              mon3: 'N',
              mon4: 'N',
              mon5: 'N',
              mon6: 'N',
              mon7: 'N',
              mon8: 'N',
              mon9: 'N',
              mon10: 'N',
              mon11: 'N',
              mon12: 'N',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        });
      }
    },
    deleteTarget() {
      let selectData = this.$refs['plantable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  //확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (!this.year.deleteTargetDepts) {
                this.year.deleteTargetDepts = [];
              }
              if (item.editFlag !== 'C' && this.$_.findIndex(this.year.deleteTargetDepts, { deptCd: item.deptCd }) === -1) {
                this.year.deleteTargetDepts.push(item)
              }
              this.grid.data = this.$_.reject(this.grid.data, item);
            });
            this.$refs['plantable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTeam() {
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.year.auditTeams, { userId: item.userId }) === -1) {
            _data.push({
              saiInternalYearPlanId: this.popupParam.saiInternalYearPlanId,
              deptCd: item.deptCd, deptName: item.deptName, 
              userId: item.userId, userName: item.userName, 
              saiInternalAuditTeamRoleCd: 'IATR000005',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.year.auditTeams = this.$_.concat(_data, this.year.auditTeams)
      }
    },
    deleteTeam() {
      let selectData = this.$refs['teamtable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  //확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (!this.year.deleteAuditTeams) {
                this.year.deleteAuditTeams = [];
              }
              if (item.editFlag !== 'C' && this.$_.findIndex(this.year.deleteAuditTeams, { userId: item.userId }) === -1) {
                this.year.deleteAuditTeams.push(item)
              }
              this.year.auditTeams = this.$_.reject(this.year.auditTeams, item);
            });
            this.$refs['teamtable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>